import { useEffect, useState } from "react";
import * as admin_api from "./firebase/admin-question";
import type { AQuestion } from "./_model";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Fieldset } from "primereact/fieldset";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

let question_ref = undefined as HTMLInputElement;

export default function ManageOpenQuestions() {
  const [$clouds, set_clouds] = useState(undefined as AQuestion[]);
  const [$dialog, set_dialog] = useState(undefined as AQuestion);
  const [$update_id, set_update_id] = useState(undefined as string);
  useEffect(() => {
    question_ref = undefined;
    admin_api.onAllChanged((value) => {
      set_clouds(value as any);
    });

    return () => {
      question_ref = undefined;
    };
  }, []);

  function goToLink(play: boolean, id: number | string) {
    const link =
      window.origin +
      `/${play ? "play" : "monitor"}/open-question/${String(id)}`;
    window.open(link, "_blank");
  }
  return (
    //
    <Fieldset legend="Open Questions" className="flex flex-col w-full h-fit">
      <div className="flex flex-row w-full my-2 space-x-2 items-center justify-end children:(rounded-lg px-2 py-2)">
        <span
          className="bg-green-100"
          onClick={() =>
            set_dialog({ question: "New Question..", answers: {} } as any)
          }
        >
          Create New
        </span>

        <span className="bg-red-100" onClick={() => admin_api.deleteAll()}>
          Delete All
        </span>
      </div>
      <DataTable value={$clouds} tableStyle={{ minWidth: "50rem" }}>
        <Column
          header="ID"
          bodyStyle={{ width: "10%" }}
          body={(data, options) => {
            console.log("data :: ", data);
            return <div>{options.rowIndex}</div>;
          }}
        />
        <Column field="question" header="Question" />
        <Column
          header="Links"
          body={(data, options) => {
            return (
              <div className="flex flex-row space-x-2 children:(rounded-lg px-2 py-2)">
                <span
                  className="bg-green-100"
                  onClick={() => goToLink(true, options.rowIndex)}
                >
                  Play
                </span>
                <span
                  className="bg-purple-100"
                  onClick={() => goToLink(false, options.rowIndex)}
                >
                  Monitor
                </span>
              </div>
            );
          }}
        />
        <Column
          bodyStyle={{ width: "20%" }}
          header={"Actions"}
          body={(data, options) => {
            return (
              <div className="flex flex-row space-x-2">
                <span
                  onClick={() => {
                    set_update_id(String(options.rowIndex));
                    set_dialog(data);
                  }}
                >
                  update
                </span>
                <span onClick={() => admin_api.deleteID(options.rowIndex)}>
                  delete
                </span>
              </div>
            );
          }}
        />
      </DataTable>
      <Dialog
        header="Header"
        visible={$dialog !== undefined}
        style={{ width: "50vw" }}
        onHide={() => {
          set_update_id(undefined);
          set_dialog(undefined);
        }}
        className="flex flex-col w-full "
      >
        <InputText
          ref={(ref) => (question_ref = ref)}
          className="w-full my-2"
          type="text"
          defaultValue={$dialog?.question}
        />
        <Button
          className="self-end"
          onClick={() => {
            ($update_id
              ? admin_api.updateQuestion({
                  id: $update_id,
                  question: question_ref.value,
                })
              : admin_api.addNew({
                  clouds: $clouds,
                  new: { ...$dialog, question: question_ref.value },
                })
            ).then(() => {
              set_update_id(undefined);
              set_dialog(undefined);
            });
          }}
        >
          {$update_id ? "Update" : "Submit"}
        </Button>
      </Dialog>
    </Fieldset>
  );
}
